<template>
  <!-- 404 Page -->
    <main id="notfound" class="min-h-[calc(100vh-4rem)] flex items-center justify-center bg-cover bg-center bg-portfolio-home font-primary">
      <div class="container text-center py-20">
        <h1 class="text-6xl font-bold">404</h1>
        <p class="mt-4 text-xl">Page not found :(</p>
      </div>
</main>
</template>
  
  <script>
  export default {
    name: "NotFound",
  };
  </script>  